import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CreateUserModal = ({ newUser, handleInputChange, setIsCreateModalOpen, handleAddUser }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-md w-96 shadow-lg">
        <h2 className="text-xl font-bold mb-4">Create New User</h2>
        
        <div className="mb-3">
          <label className="block text-gray-700">Username</label>
          <input
            type="text"
            name="username"
            value={newUser.username}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        
        <div className="mb-3 relative">
          <label className="block text-gray-700">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={newUser.password}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <div className="absolute inset-y-0 right-3 top-6 flex items-center">
            {showPassword ? (
              <FaEyeSlash
                onClick={toggleShowPassword}
                className="cursor-pointer text-gray-500"
              />
            ) : (
              <FaEye
                onClick={toggleShowPassword}
                className="cursor-pointer text-gray-500"
              />
            )}
          </div>
        </div>

        <div className="mb-3">
          <label className="block text-gray-700">Trunk</label>
          <input
            type="text"
            name="trunk"
            value={newUser.trunk}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="mb-3">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div className="mb-3">
          <label className="block text-gray-700">Role</label>
          <div className="flex space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="admin"
                checked={newUser.role === "admin"}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">Admin</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="user"
                checked={newUser.role === "user"}
                onChange={handleInputChange}
                className="form-radio"
              />
              <span className="ml-2">User</span>
            </label>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setIsCreateModalOpen(false)}
            className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={handleAddUser}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Add User
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateUserModal;