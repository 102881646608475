import React, { useState, useEffect } from 'react';
import { getInboundSMS } from '../../services/users/user';
import SearchBar from '../../components/SearchBar';

const InboundSMS = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchInboundSMS = async () => {
    try {
      const res = await getInboundSMS();
      const smsData = Array.isArray(res.data) ? res.data : [];
      setData(smsData);
    } catch (error) {
      console.error(error);
      setData([]); 
    }
  };

  useEffect(() => {
    fetchInboundSMS();
  }, []);

  const filteredSMS = data.filter(sms =>
    (sms.source_addr && sms.source_addr.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (sms.sms_content && sms.sms_content.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-black">Inbound SMS</h2>
        <SearchBar placeholder="Search SMS..." onSearch={setSearchTerm} />
      </div>

      <div className="overflow-auto">
        <table className="min-w-full bg-white border border-gray-300 border-none">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">Sender</th>
              <th className="px-4 py-2 text-left">Message</th>
              <th className="px-4 py-2 text-left">Received At</th>
            </tr>
          </thead>
          <tbody>
            {filteredSMS.length > 0 ? (
              filteredSMS.map((sms, index) => (
                <tr key={index} className="hover:bg-gray-100 border rounded-full">
                  <td className="px-4 py-2">{sms.source_addr || 'N/A'}</td>
                  <td className="px-4 py-2">{sms.sms_content || 'N/A'}</td>
                  <td className="px-4 py-2">{sms.created_at || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center border border-gray-300 px-4 py-2">
                  No inbound SMS found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InboundSMS;