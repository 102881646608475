import "./App.css";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Auth from "./pages/Auth/Auth";
import InnerRoute from "./InnerRoute";
import Navbar from "./components/Navbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsAuth(true);
    }
  }, []);

  return (
    <div className="App">
      {isAuth && location.pathname !== '/auth' && <Navbar setIsAuth={setIsAuth} />}
      <ToastContainer />
      <Routes>
        {isAuth ? (
          <>
            <Route path="/" element={<Navigate to="/inbound-sms" />} />
            <Route path="/*" element={<InnerRoute />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/auth" />} />
            <Route path="/auth" element={<Auth setIsAuth={setIsAuth} />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;