import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { resetPassword } from "../../services/auth/auth";

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");
  const username = queryParams.get("username");

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
  
    setError("");
  
    try {
      await resetPassword( {token:token, emailOrUsername:(email?email:username), new_password:newPassword });
      toast.success("Password reset successfully!");
      navigate("/auth");
    } catch (err) {
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-purple-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-6 text-purple-600 text-center">
          Reset Password
        </h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
              placeholder="Enter new password"
              required
            />
            <div className="absolute inset-y-0 top-5 right-3 flex items-center">
              {showNewPassword ? (
                <FaEyeSlash
                  onClick={toggleShowNewPassword}
                  className="cursor-pointer text-gray-500"
                />
              ) : (
                <FaEye
                  onClick={toggleShowNewPassword}
                  className="cursor-pointer text-gray-500"
                />
              )}
            </div>
          </div>
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
              placeholder="Confirm new password"
              required
            />
            <div className="absolute inset-y-0 top-5 right-3 flex items-center">
              {showConfirmPassword ? (
                <FaEyeSlash
                  onClick={toggleShowConfirmPassword}
                  className="cursor-pointer text-gray-500"
                />
              ) : (
                <FaEye
                  onClick={toggleShowConfirmPassword}
                  className="cursor-pointer text-gray-500"
                />
              )}
            </div>
          </div>
          {error && (
            <p className="text-red-500 text-center mt-4">{error}</p>
          )}
          <div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition duration-300"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;