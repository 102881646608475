import React, { useState } from 'react';
import { forgotPassword } from '../../services/auth/auth';
import { toast } from 'react-toastify';

const UserModal = ({
  newUserDetails,
  handleInputChange,
  setIsModalOpen,
  handleUpdateUser,
  fetchUsers
}) => {
  const [passwordResetMessage, setPasswordResetMessage] = useState("");

  const handleResetPassword = async () => {
    try {
      const res = await forgotPassword({ username: newUserDetails.username });
      if (res.success) {
        setPasswordResetMessage("Password reset link sent to user's email.");
        toast.success("Password reset link sent!", { autoClose: 2000 });
        fetchUsers();
      } else {
        setPasswordResetMessage("Failed to send reset link. Try again.");
        toast.error("Failed to send reset link.", { autoClose: 2000 });
      }
    } catch (err) {
      console.error("Error sending reset link:", err);
      setPasswordResetMessage("Error occurred. Try again.");
      toast.error("Error sending reset link.", { autoClose: 2000 });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-md w-96 shadow-lg">
        <h2 className="text-xl font-bold mb-4">Edit User Details</h2>

        {/* Username */}
        <div className="mb-3">
          <label className="block text-gray-700">Username</label>
          <input
            type="text"
            name="username"
            value={newUserDetails.username}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            disabled
          />
        </div>

        {/* Trunk */}
        <div className="mb-3">
          <label className="block text-gray-700">Trunk</label>
          <input
            type="text"
            name="trunk"
            value={newUserDetails.trunk}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Email */}
        <div className="mb-3">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={newUserDetails.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Role selection */}
        <div className="mb-3">
          <label className="block text-gray-700">Role</label>
          <div className="flex items-center space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="admin"
                checked={newUserDetails.role === "admin"}
                onChange={handleInputChange}
                className="form-radio text-blue-600"
              />
              <span className="ml-2">Admin</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="role"
                value="user"
                checked={newUserDetails.role === "user"}
                onChange={handleInputChange}
                className="form-radio text-blue-600"
              />
              <span className="ml-2">User</span>
            </label>
          </div>
        </div>

        {/* Reset Password */}
        <div className="mb-4">
          <button
            onClick={handleResetPassword}
            className="text-blue-500 underline"
          >
            Reset Password
          </button>
          {passwordResetMessage && (
            <p className="text-green-500 mt-2">{passwordResetMessage}</p>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleUpdateUser()
              setIsModalOpen(false);
            }}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserModal;