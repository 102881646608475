import React from 'react';
import { NavLink } from 'react-router-dom';

const Tabs = () => {
  return (
    <nav className="flex space-x-4 my-4">
      <NavLink
        to="/inbound-sms"
        className={({ isActive }) =>
          `px-4 py-2 rounded-md transition-colors duration-300 ${
            isActive
              ? 'bg-purple-600 text-white'
              : 'bg-white text-gray-600 hover:bg-gray-100'
          }`
        }
      >
        Inbound SMS
      </NavLink>
      <NavLink
        to="/user"
        className={({ isActive }) =>
          `px-4 py-2 rounded-md transition-colors duration-300 ${
            isActive
              ? 'bg-purple-600 text-white' 
              : 'bg-white text-gray-600 hover:bg-gray-100'
          }`
        }
      >
        Users
      </NavLink>
    </nav>
  );
};

export default Tabs;