import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getLogoAndTitle,
  putLogoandTitle,
  updateUser,
} from "../../services/users/user";
import { resetPasswordFromProfile } from "../../services/auth/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Profile = ({ user, logo, setLogo }) => {
  const [newUserDetails, setNewUserDetails] = useState({
    email: user.email,
    username: user.username,
    trunk: user.trunk,
    password: "",
    oldPassword: "",
    newPassword: "",
    websiteTitle: document.title,
  });
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

  const toggleShowOldPassword = () =>
    setIsOldPasswordVisible(!isOldPasswordVisible);
  const toggleShowNewPassword = () =>
    setIsNewPasswordVisible(!isNewPasswordVisible);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogo(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  

  const updateLogoAndTitle = async () => {
    try {
      if (typeof logo === 'string') {
        const logoDataUrl = logo.split(",")[1];  // Only split if `logo` is a string
  
        const data = {
          logo: logoDataUrl,
          title: newUserDetails.websiteTitle,
        };
  
        const response = await putLogoandTitle(data);
  
        if (response.success) {
          const link = document.querySelector("link[rel='icon']");
          link.href = `data:image/png;base64,${logoDataUrl}`;
          document.title = newUserDetails.websiteTitle;
          setLogo(link.href);  // Store the updated logo in the state
          toast.success("Logo and website title updated successfully!");
        } else {
          toast.error("Failed to update logo and website title.");
        }
      } else {
        toast.error("Logo must be a valid image file.");
      }
    } catch (error) {
      console.error("Error updating logo and title:", error);
      toast.error("Error updating logo and website title.");
    }
  };
  

  const togglePasswordChange = () => {
    setIsPasswordChange(!isPasswordChange);
  };

  const handleUpdateUser = async () => {
    const payload = {
      Id: user.id,
      Password: newUserDetails.password,
      Trunk: newUserDetails.trunk,
      Email: newUserDetails.email,
    };

    try {
      const res = await updateUser({ id: user.id, payload });
      if (res.success) {
        toast.success("User updated successfully!", { autoClose: 2000 });
      } else {
        toast.error("Failed to update user. Please try again.", {
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.error("Error updating user:", err);
      toast.error("Error updating user.", { autoClose: 2000 });
    }
  };

  const handleChangePassword = async () => {
    if (!newUserDetails.oldPassword || !newUserDetails.newPassword) {
      toast.error("Please fill in both old and new password fields.");
      return;
    }

    try {
      const res = await resetPasswordFromProfile({
        username: user.username,
        old_password: newUserDetails.oldPassword,
        new_password: newUserDetails.newPassword,
      });
      if (res.success) {
        toast.success("Password changed successfully!");
        setIsPasswordChange(false);
      } else {
        toast.error("Failed to change password. Please try again.");
      }
    } catch (err) {
      console.error("Error changing password:", err);
      toast.error("Error changing password.", { autoClose: 2000 });
    }
  };

  return (
    <div className="mx-auto p-6 rounded-lg">
      <h2 className="text-3xl font-bold mb-8 text-gray-800 border-b pb-2">
        User Profile
      </h2>
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              name="username"
              value={newUserDetails.username}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
              disabled
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={newUserDetails.email}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Trunk</label>
          <input
            type="text"
            name="trunk"
            value={newUserDetails.trunk}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div className="flex justify-between items-center">
          <button
            onClick={togglePasswordChange}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            {isPasswordChange ? "Cancel Password Change" : "Change Password"}
          </button>
          <button
            onClick={() => {
              handleUpdateUser();
            }}
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
          >
            Update Profile
          </button>
        </div>

        {isPasswordChange && (
          <div className="space-y-4 mt-6 p-4 bg-gray-50 rounded-md w-1/3">
            <div className="mb-3 flex items-center relative space-y-2 space-x-2">
              <label className="text-sm font-medium text-gray-700 w-1/2">
                Old Password
              </label>
              <input
                type="password"
                name="oldPassword"
                value={newUserDetails.oldPassword}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <div className="absolute inset-y-0 right-3 flex items-center">
                {isOldPasswordVisible ? (
                  <FaEyeSlash
                    onClick={toggleShowOldPassword}
                    className="cursor-pointer text-gray-500"
                  />
                ) : (
                  <FaEye
                    onClick={toggleShowOldPassword}
                    className="cursor-pointer text-gray-500"
                  />
                )}
              </div>
            </div>
            <div className="mb-3 flex items-center relative space-y-2 space-x-2">
              <label className="text-sm font-medium text-gray-700 w-1/2">
                New Password
              </label>
              <input
                type="password"
                name="newPassword"
                value={newUserDetails.newPassword}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <div className="absolute inset-y-0 right-3 flex items-center">
                {isNewPasswordVisible ? (
                  <FaEyeSlash
                    onClick={toggleShowNewPassword}
                    className="cursor-pointer text-gray-500"
                  />
                ) : (
                  <FaEye
                    onClick={toggleShowNewPassword}
                    className="cursor-pointer text-gray-500"
                  />
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleChangePassword}
                className="bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700 transition duration-300 ease-in-out"
              >
                Change Password
              </button>
            </div>
          </div>
        )}
      </div>

      {user.role === "admin" && (
        <div className="mt-12 space-y-8">
          <h3 className="text-2xl font-semibold text-gray-800 border-b pb-2">
            Admin Settings
          </h3>
          <div className="flex w-2/3 justify-start gap-6">
            <div className="flex items-center gap-2">
              <label className="text-sm font-medium text-gray-700">
                Website Title
              </label>
              <input
                type="text"
                name="websiteTitle"
                value={newUserDetails.websiteTitle}
                onChange={handleInputChange}
                className="flex-grow p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <div className="flex items-center gap-2">
              <label className="text-sm font-medium text-gray-700">
                Logo Upload
              </label>
              <div className="flex space-x-2">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  className="flex-grow p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>

            <button
            onClick={updateLogoAndTitle}
            className=" bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
          >
            Update Logo & Title
          </button>
          </div>

        </div>
      )}
    </div>
  );
};

export default Profile;
