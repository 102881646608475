import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import InboundSMS from './pages/InboundSMS/InboundSMS';
import User from './pages/Users/User';
import Tabs from './components/Tabs';
import Profile from './pages/Profile/Profile';

const InnerRoute = ({logo,setLogo}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "admin";
  const location = useLocation();

  const showTabs = location.pathname !== '/profile';

  return (
    <div className="flex flex-col items-center">
      {showTabs && isAdmin && <Tabs />}
      <div className="w-full max-w-screen">
        <Routes>
          <Route path="/inbound-sms" element={<InboundSMS user={user}/>} />
          {isAdmin && <Route path="/user" element={<User />} />}
          <Route path='/profile' element={<Profile user={user} logo={logo} setLogo={setLogo}/>} />
        </Routes>
      </div>
    </div>
  );
};

export default InnerRoute;