import axios from "axios";
import { apiConfig } from "../apiConfig";

const getUsers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const res = await axios.get(`${apiConfig.baseURL}/nissen/v1/users`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        });

        return res.data; 
    } catch (e) {
        console.log('Error fetching users:', e.message); 
        throw e; 
    }
};

const addUser = async ({ payload }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
       const res = await axios.post(`${apiConfig.baseURL}/nissen/v1/user`,payload,{
        headers:{
            "Authorization": `Bearer ${accessToken}`,
        }
    })
      return res.data;
    } catch (e) {
      console.log('Error adding new user:', e.message);
      throw e;
    }
}

const getInboundSMS =  async() =>{
    const accessToken = localStorage.getItem("accessToken");
    try{
        const res = await axios.get(`${apiConfig.baseURL}/nissen/v1/inbound-sms`,{
            headers:{
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return res.data;
    }catch(e){
        console.log('Error fetching inbound sms',e.message);
        throw e;
    }
}
export { getUsers ,addUser,getInboundSMS};