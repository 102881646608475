import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { forgotPassword } from "../../services/auth/auth"; 

const PasswordRecovery = () => {
  const [recoveryMethod, setRecoveryMethod] = useState("email");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleRecovery = async () => {
    if (!inputValue) {
      setError(`${recoveryMethod === "email" ? "Email" : "Username"} is required`);
      return;
    }
    
    setLoading(true);
    setError("");
    setSuccess("");

    const requestData = recoveryMethod === "email" ? { email: inputValue } : { username: inputValue };
    
    console.log(requestData)
    try {
      const response = await forgotPassword(requestData);
      setSuccess("Password recovery instructions sent to your " + recoveryMethod + ".");
      toast.success("Password recovery instructions sent!");
    } catch (err) {
      setError("An error occurred while sending recovery instructions.");
      toast.error("Failed to send recovery instructions.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-purple-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96 relative">
        <button
          onClick={() => navigate("/auth")}
          className="absolute top-10 left-8 text-purple-600 hover:text-purple-800"
        >
          <FaArrowLeft size={20} />
        </button>
        
        <h2 className="text-2xl font-bold mb-6 text-purple-600 text-center">
          Password Recovery
        </h2>

        {/* Radio button to choose between email or username */}
        <div className="flex justify-center mb-4">
          <div className="mr-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="email"
                checked={recoveryMethod === "email"}
                onChange={() => setRecoveryMethod("email")}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">By Email</span>
            </label>
          </div>
          <div>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="username"
                checked={recoveryMethod === "username"}
                onChange={() => setRecoveryMethod("username")}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">By Username</span>
            </label>
          </div>
        </div>

        <form className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {recoveryMethod === "email" ? "Email Address" : "Username"}
            </label>
            <input
              type={recoveryMethod === "email" ? "email" : "text"}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
              placeholder={`Enter your ${recoveryMethod}`}
            />
          </div>
          <div>
            <button
              type="button"
              onClick={handleRecovery}
              className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition duration-300"
              disabled={loading}
            >
              {loading ? "Processing..." : "Recover Password"}
            </button>
          </div>
        </form>

        {error && (
          <p className="text-red-500 text-center mt-4">
            {error}
          </p>
        )}
        
        {success && (
          <p className="text-green-500 text-center mt-4">
            {success}
          </p>
        )}
      </div>
    </div>
  );
};

export default PasswordRecovery;