import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const Navbar = ({ setIsAuth }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("User logged out");
    setIsAuth(false);
    navigate("/auth");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="flex justify-between items-center p-4 bg-white text-purple-600 border-b border-gray-300">
      <Link to="/inbound-sms" className="text-xl font-bold">
        Nissen
      </Link>
      <div className="flex items-center gap-4">
        <div className="relative">
          <button onClick={toggleDropdown} className="focus:outline-none">
            <FaUserCircle className="text-3xl"/>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-10">
              <div
                className="py-2 px-4 hover:bg-purple-100 cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;