import React, { useState, useEffect } from 'react';
import { getInboundSMS } from '../../services/users/user';
import SearchBar from '../../components/SearchBar';

const InboundSMS = ({ user }) => {
  const [adminData, setAdminData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchInboundSMS = async () => {
    try {
      const res = await getInboundSMS();
      if (user.role === 'user') {
        setUserData(res.data);
      } else {
        setAdminData(res.data);
      }
    } catch (error) {
      console.error(error);
      setAdminData([]);
      setUserData([]);
    }
  };

  useEffect(() => {
    fetchInboundSMS();
  }, []);

  const filteredSMS = adminData.filter(sms =>
    (sms.source_addr && sms.source_addr.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (sms.sms_content && sms.sms_content.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-black">Inbound SMS</h2>
        <SearchBar placeholder="Search SMS..." onSearch={setSearchTerm} />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2">Sender</th>
              <th className="px-4 py-2">Sender ID</th>
              <th className="px-4 py-2">SMS Number</th>
              <th className="px-4 py-2">Your Message</th>
              <th className="px-4 py-2">Received At</th>
            </tr>
          </thead>
          <tbody>
            {user.role === 'user' ? (
              userData.length > 0 ? (
                userData.map((sms) => (
                  <tr key={sms.id} className="hover:bg-gray-100 border rounded-full text-center">
                    <td className="px-4 py-2">{sms.username || 'N/A'}</td>
                    <td className="px-4 py-2">{sms.source_addr || 'N/A'}</td>
                    <td className="px-4 py-2">{sms.trunk || 'N/A'}</td>
                    <td className="px-4 py-2">{sms.sms_content || 'N/A'}</td>
                    <td className="px-4 py-2">{sms.created_at || 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr key={userData.id} className="hover:bg-gray-100 border rounded-full text-center">
                    <td className="px-4 py-2">{userData.username || 'N/A'}</td>
                    <td className="px-4 py-2">{userData.source_addr || 'N/A'}</td>
                    <td className="px-4 py-2">{userData.trunk || 'N/A'}</td>
                    <td className="px-4 py-2">{userData.sms_content || 'N/A'}</td>
                    <td className="px-4 py-2">{userData.created_at || 'N/A'}</td>
                  </tr>
              )
            ) : filteredSMS.length > 0 ? (
              filteredSMS.map((sms, index) => (
                <tr key={index} className="hover:bg-gray-100 border rounded-full text-center">
                  <td className="px-4 py-2">{sms.username || 'N/A'}</td>
                  <td className="px-4 py-2">{sms.source_addr || 'N/A'}</td>
                  <td className="px-4 py-2">{sms.trunk || 'N/A'}</td>
                  <td className="px-4 py-2">{sms.sms_content || 'N/A'}</td>
                  <td className="px-4 py-2">{sms.created_at || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InboundSMS;
