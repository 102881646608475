import axios from "axios";
import { apiConfig } from "../apiConfig";

const login = async ({ payload }) => {
  try {
    const res = await axios.post(apiConfig.baseURL + '/nissen/v1/login', payload);    
    return res.data;
  } catch (e) {
    console.log('Error logging in:', e.message);
    throw e;
  }
}

export { login };