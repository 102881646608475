import React, { useEffect, useState } from "react";
import { getUsers, addUser } from "../../services/users/user";
import { toast } from "react-toastify";
import SearchBar from "../../components/SearchBar";

const User = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    trunk: "",
    email: "",
  });

  const fetchUsers = async () => {
    try {
      const res = await getUsers();
      setUsers(res.data);
    } catch (err) {
      console.error("Error fetching users:", err);
      toast.error("Error fetching users.", { autoClose: 2000 });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleAddUser = async () => {
    try {
      const res = await addUser({ payload: newUser });
      if (res.success) {
        toast.success("User created successfully!", { autoClose: 2000 });
        fetchUsers();
        setIsModalOpen(false);
        setNewUser({ username: "", password: "", trunk: "", email: "" });
      } else {
        toast.error("Failed to create user. Please try again.", {
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.error("Error adding new user:", err);
      toast.error("Error adding new user.", { autoClose: 2000 });
    }
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold mb-4 text-black">Users</h2>
        <div className="flex justify-between items-center">
          <SearchBar placeholder="Search Users..." onSearch={setSearchTerm} />
          <button
            onClick={() => setIsModalOpen(true)}
            className="border border-purple-500 text-purple-500 px-4 py-2 mb-4 ml-2 rounded-md hover:bg-purple-500 hover:text-white"
          >
            Create User
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4">Username</th>
              <th className="py-2 px-4">Email</th>
              <th className="py-2 px-4">Trunk</th>
              <th className="py-2 px-4">Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id} className="hover:bg-gray-100 text-center">
                <td className="py-2 px-4">{user.username || "N/A"}</td>
                <td className="py-2 px-4">{user.email}</td>
                <td className="py-2 px-4">{user.trunk}</td>
                <td className="py-2 px-4">
                  {new Date(user.created_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Create User Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md w-96 shadow-lg">
            <h2 className="text-xl font-bold mb-4">Create New User</h2>
            <div className="mb-3">
              <label className="block text-gray-700">Username</label>
              <input
                type="text"
                name="username"
                value={newUser.username}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label className="block text-gray-700">Trunk</label>
              <input
                type="text"
                name="trunk"
                value={newUser.trunk}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleAddUser}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default User;
