import React from "react";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ placeholder, onSearch }) => {
  return (
    <div className="mb-4 relative">
      <input
        type="text"
        placeholder={placeholder}
        onChange={(e) => onSearch(e.target.value)}
        className="border border-[1px] px-4 py-2 pl-10 w-full shadow-sm focus:outline-none rounded-md"
      />
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
        <FaSearch className="h-5 w-5 text-gray-400" />
      </div>
    </div>
  );
};

export default SearchBar;