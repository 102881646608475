import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InboundSMS from './pages/InboundSMS/InboundSMS';
import User from './pages/Users/User';
import Tabs from './components/Tabs';

const InnerRoute = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "admin";

  return (
    <div className="flex flex-col items-center">
      {isAdmin && <Tabs />}
      <div className="w-full max-w-screen">
        <Routes>
          <Route path="/inbound-sms" element={<InboundSMS />} />
          {isAdmin && <Route path="/user" element={<User />} />}
        </Routes>
      </div>
    </div>
  );
};

export default InnerRoute;