import axios from "axios";
import { apiConfig } from "../apiConfig";

const getUsers = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const res = await axios.get(`${apiConfig.baseURL}/nissen/v1/users`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
        });

        return res.data; 
    } catch (e) {
        console.log('Error fetching users:', e.message); 
        throw e; 
    }
};

const addUser = async ({ payload }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
       const res = await axios.post(`${apiConfig.baseURL}/nissen/v1/user`,payload,{
        headers:{
            "Authorization": `Bearer ${accessToken}`,
        }
    })
      return res.data;
    } catch (e) {
      console.log('Error adding new user:', e.message);
      throw e;
    }
}

const updateUser = async ({ payload }) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
        const url = `${apiConfig.baseURL}/nissen/v1/user`;
        const res = await axios.patch(url, payload, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        return res.data; 
    } catch (e) {
        console.log('Error updating user:', e.message);
        throw e;
    }
};

const deleteUser = async (user) => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/user`;
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: user,
    });

    return res.data;
  } catch (e) {
    console.log('Error deleting user:', e.message);
    throw e;
  }
};

const getInboundSMS = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const url = `${apiConfig.baseURL}/nissen/v1/inbound-sms`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      return res.data;
    } catch (e) {
      console.log('Error fetching inbound SMS:', e.message);
      throw e;
    }
  };
  
const putLogoandTitle = async (data) =>{
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/logo-title`;
    const res = await axios.put(url, data,{
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  } catch(e){
    console.log('Error updating logo and title:', e.message);
    throw e;
  }
}
const getLogoAndTitle = async () => {
  const accessToken = localStorage.getItem("accessToken");
  try {
    const url = `${apiConfig.baseURL}/nissen/v1/logo-title`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data.data;
  } catch (e) {
    console.log('Error fetching logo and title:', e.message);
    throw e;
  }
};

export { getUsers ,addUser,updateUser,deleteUser,getInboundSMS,putLogoandTitle,getLogoAndTitle};