import axios from "axios";
import { apiConfig } from "../apiConfig";

// Login API call
const login = async ({ payload }) => {
  try {
    const res = await axios.post(apiConfig.baseURL + '/nissen/v1/login', payload);    
    return res.data;
  } catch (e) {
    console.log('Error logging in:', e.message);
    throw e;
  }
}

const forgotPassword = async (data) => {
  try {
    const res = await axios.post(apiConfig.baseURL + '/nissen/v1/forgot-password', data);
    return res.data;
  } catch (e) {
    console.log('Error during forgot password request:', e.message);
    throw e;
  }
};

const resetPassword = async ({ token, emailOrUsername, new_password }) => {
  console.log(token,emailOrUsername,new_password)
  try {
    const res = await axios.get(apiConfig.baseURL + '/nissen/v1/reset-password', {
      params: { token, new_password, ...(emailOrUsername.includes('@') ? { email: emailOrUsername } : { username: emailOrUsername }) },
    });
    return res.data;
  } catch (e) {
    console.log('Error during reset password request:', e.message);
    throw e;
  }
};


const resetPasswordFromProfile = async ({ username, old_password, new_password }) => {
  try {
    const res = await axios.post(
      apiConfig.baseURL + "/nissen/v1/reset-password",
      { username, old_password, new_password },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log("Error during reset password request:", e.message);
    throw e;
  }
};
export { login, forgotPassword, resetPassword ,resetPasswordFromProfile};