import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { login } from "../../services/auth/auth";
import { toast } from "react-toastify";

const Auth = ({ setIsAuth }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!formData.username) {
      toast.error("Username cannot be empty");
    } else if (!formData.password) {
      toast.error("Password cannot be empty");
    } else {
      const payload = {
        username: formData.username,
        password: formData.password,
      };
      try {
        const res = await login({ payload });
        if (res.success) {
          localStorage.setItem("accessToken", res.data.token.AccessToken);
          localStorage.setItem("user", JSON.stringify(res.data.user));
  
          setIsAuth(true);
          toast.success("Login successful!", { autoClose: 2000 });
          navigate("/inbound-sms");
        } else {
          toast.error(res.message || "Login failed. Please try again.", { autoClose: 2000 });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };  

  return (
    <div className="min-h-screen flex items-center justify-center bg-purple-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-6 text-purple-600 text-center">
          Login
        </h2>
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
              placeholder="Enter your username"
            />
          </div>
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
              placeholder="Enter your password"
            />
            <div className="absolute inset-y-0 top-5 right-3 flex items-center">
              {showPassword ? (
                <FaEyeSlash
                  onClick={toggleShowPassword}
                  className="cursor-pointer text-gray-500"
                />
              ) : (
                <FaEye
                  onClick={toggleShowPassword}
                  className="cursor-pointer text-gray-500"
                />
              )}
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={handleLogin}
              className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition duration-300"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Auth;